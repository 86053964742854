import Stepper from '@/components/Stepper/Stepper.vue'
import Finput from '@/components/Finput/Finput.vue'
import FAQ from '@/components/FAQ/FAQ.vue'


export default {
    name: 'Home',
    components: {
        Stepper,
        Finput,
        FAQ
    },
    props: {
        faqs: Object
    },
    methods: {
        setActiveFeature: function (index) {
            this.activeFeatureIndex = index
        },
        changeTab: function (tab) {
            if (tab === 'person') {
                this.$router.replace({
                    query: null
                })
            } else {
                this.$router.replace({
                    query: {
                        t: 'c'
                    }
                })
            }
        },
        getLostIllu: function () {
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return 'lost-mobile'
            } else if (window.innerWidth > 2000) {
                return 'lost-huge'
            } else if (window.innerWidth > 1500) {
                return 'lost-big'
            } else {
                return 'lost'
            }
        },
        hideDemo: function () {
            this.d.platform.features[this.activeFeatureIndex].showDemo = false
        },
        getFeatureSrc: function (content, preview = false) {
            if (preview) {
                return (window.innerWidth < 600 ?
                    content.mobilePreview :
                    content.preview)
            } else {
                return (window.innerWidth < 600 ?
                    content.mobileSrc :
                    content.src)
            }
        },
        pauseFactsAnim: function () {
            this.stopFactBoxAnim = true
            setTimeout(() => {
                this.stopFactBoxAnim = false
            }, 3000)
        },
        factsAni: function () {
            this.activeFact = 0
            console.log(this.activeFact)
            setInterval(() => {
                this.activeFact++
                if (this.activeFact >= this.facts.length) this.activeFact = 0
            }, 6000)
        },

    },
    data: function () {
        return {
            activeFact: null,
            demoActive: false,
            stopFactBoxAnim: false,
            activeFeatureIndex: 1,
            facts: [{
                text: '90 percent of millennials want sustainable investing options as part of their 401(k) plans.',
                link: 'https://tnj.com/csr-reporting-the-expanding-field-of-corporate-citizenship/',
                author: {
                    id: 'ms',
                    logo: '//logo.clearbit.com/morganstanley.com',
                    name: 'Morgan Stanley'
                }
            }, {
                text: '62% would take a pay cut to work for a more responsible company.',
                link: 'https://www.conecomm.com/research-blog/2015-cone-communications-millennial-csr-study#download-research',
                author: {
                    id: 'cone',
                    logo: '//logo.clearbit.com/conecomm.com',
                    name: 'Cone Communications'
                }
            }, {
                text: 'Corporate responsibility performance reduce annual quit rates, saving replacement costs up to 90%-200% of an employee’s annual salary.',
                link: 'https://hbr.org/2016/10/the-comprehensive-business-case-for-sustainability',
                author: {
                    id: 'harvard',
                    logo: '//logo.clearbit.com/harvard.edu',
                    name: 'Harvard'
                }
            }, {
                text: 'Customers are ready to spend 11-45% more if the company has a sustainability report, especially if its distributed through a third party.',
                link: 'https://poseidon01.ssrn.com/delivery.php?ID=254067068126023005121003086031090105022014038021002005108125071028021072000125066120033036018034122000060028118005007095004004025085028077060111085084105031070001025026040002122068107066115113083024002106112117116108079125023079104074028107118098068&EXT=pdf',
                author: {
                    id: 'appalachian',
                    logo: '//logo.clearbit.com/appstate.edu',
                    name: 'Appalachian'
                }
            }, {
                text: '56 percent of Millennials have ruled out ever working for a particular organization because of its values or standard of conduct.',
                link: 'https://www2.deloitte.com/content/dam/Deloitte/global/Documents/About-Deloitte/gx-millenial-survey-2016-exec-summary.pdf',
                author: {
                    id: 'deloitte',
                    logo: '//logo.clearbit.com/deloitte.com',
                    name: 'Deloitte'
                }
            }, {
                text: '82% believe they have a responsibility to purchase products that are good for the environment and society in emerging markets',
                link: 'https://hbr.org/2016/10/the-comprehensive-business-case-for-sustainability',
                author: {
                    id: 'harvard',
                    logo: '//logo.clearbit.com/harvard.edu',
                    name: 'Harvard'
                }
            }, {
                text: '57% of millennial have stopped investing in a company because of its impact on people’s well being.',
                link: 'https://www.msci.com/documents/10199/07e7a7d3-59c3-4d0b-b0b5-029e8fd3974b',
                author: {
                    id: 'msci',
                    logo: '//logo.clearbit.com/msci.com',
                    name: 'MSCI'
                }
            }, {
                text: '88% of Millennials would stop buying products from a company with bad business practices.',
                link: 'https://www.conecomm.com/research-blog/2015-cone-communications-millennial-csr-study#download-research',
                author: {
                    id: 'cone',
                    logo: '//logo.clearbit.com/conecomm.com',
                    name: 'Cone Communications'
                }
            }, {
                text: 'Nearly eight in ten (78%) investors surveyed agree that sustainable investing is a risk mitigation strategy.',
                link: 'https://www.morganstanley.com/press-releases/morgan-stanley-sustainable-signals--asset-owners-see-sustainabil',
                author: {
                    id: 'ms',
                    logo: '//logo.clearbit.com/morganstanley.com',
                    name: 'Morgan Stanley'
                }
            }, {
                text: 'Morale and employee loyalty is respectively 55% and 38% better in companies with strong sustainability programs.',
                link: 'https://hbr.org/2016/10/the-comprehensive-business-case-for-sustainability',
                author: {
                    id: 'harvard',
                    logo: '//logo.clearbit.com/harvard.edu',
                    name: 'Harvard'
                }
            }],
            logos: ['msci', 'harvard', 'deloitte', 'appalachian', 'cone', 'ms'],
            d: {},
            person: {
                id: 'person',
                top: {
                    title: "Discover companies that aren't shady.",
                    desc: "We help you find your next fair employer, product, or investment, hassle free.",
                    illu: 'person-top-illu.svg',
                    illuMobile: 'person-top-mobile.svg',
                    illuMobileSecond: 'person-top-mobile-second.svg',
                },
                facts: {
                    title: "You have morals when picking employers, products, and investments.",
                },
                lost: {
                    title: {
                        preItalics: "But it’s hard to follow these morals.",
                        italics: "You don’t know which companies are fair."
                    },
                    desc: "And that’s not your fault. It would take over 30 working days to read the sustainability reports of all the Stockholm stock exchange companies."
                },
                platform: {
                    title: 'Our platform helps you with that distinction.',
                    desc: 'Designed to be like any other everyday app. Allowing you to quickly understand which companies match with your values.',
                    features: [{
                        title: 'Discover',
                        desc: 'Curated collections',
                        content: {
                            type: 'img',
                            src: 'badges.svg',
                            mobileSrc: 'badges-mobile.svg'
                        }
                    }, {
                        title: 'Compare',
                        desc: 'Quick overview',
                        content: {
                            type: 'img',
                            src: 'platform-rankings.svg',
                            mobileSrc: 'platform-rankings-mobile.svg',
                        }
                    }, {
                        title: 'Connect',
                        desc: 'Explore an Impact Hub',
                        content: {
                            preview: 'hub-preview.svg',
                            mobilePreview: 'hub-preview-mobile.svg',
                            type: 'iframe',
                            src: 'https://demo.gokind.co/asp',
                        },
                        showDemo: false,
                        click: function () {
                            this.showDemo = true
                        }
                    }],
                },
                quote: {
                    text: 'It is very important for me that my future employer aligns with my personal values about what I believe is sustainable.',
                    person: {
                        name: 'Katharina Kotynkoweicz',
                        title: 'MsC Sustainable Communication',
                        img: 'katharina.jpg'
                    }
                },
                steps: [{
                        title: 'Join the movement',
                        desc: 'Enter your email to get started right away.',
                        img: 'person-step-1.svg'
                    },
                    {
                        title: 'Find your favorites',
                        desc: 'Those who fulfill your standards on sustainability and equality.',
                        img: 'person-step-2.svg'
                    }, {
                        title: 'Its a match!',
                        desc: 'Apply for a job, buy a fair product, or make an investment.',
                        img: 'person-step-3.svg'
                    }
                ],
            },
            company: {
                id: 'company',
                top: {
                    title: "Gain all the benefits of being a kind company.",
                    desc: "Get talents, customers, and investors by increasing the reach of your current sustainability work.",
                    illu: 'company-top.svg',
                    illuMobile: 'company-top-mobile.svg',
                    illuMobileSecond: 'company-top-mobile-second.svg',
                },
                facts: {
                    title: "Talents, customers, and investors deeply care about your great initiatives.",
                },
                lost: {
                    title: {
                        preItalics: "You’re not reaping any of these benefits.",
                        italics: "Since no one reads your sustainability report."
                    },
                    desc: "And that’s not your fault. 96% has never read a sustinability report. Still you have probably spent between 100 000 and 2 000 000 SEK creating it."
                },
                platform: {
                    title: 'Our platform guarantees that engagement.',
                    desc: 'Designed the way users are used to view information, enabling stakeholders to quickly understand that you’re a sustainable and equal company.',
                    features: [{
                        title: 'Show off',
                        desc: 'Share your progress',
                        content: {
                            type: 'img',
                            src: 'badges.svg',
                            mobileSrc: 'badges-mobile.svg'
                        }
                    }, {
                        title: 'Tell your story',
                        desc: 'Get your Impact Hub',
                        content: {
                            preview: 'hub-preview.svg',
                            mobilePreview: 'hub-preview-mobile.svg',
                            type: 'iframe',
                            src: 'https://demo.gokind.co/asp',
                        },
                        showDemo: false,
                        click: function () {
                            this.showDemo = true
                        }
                    }, {
                        title: 'Connect',
                        desc: 'Match with stakeholders',
                        content: {
                            type: 'img',
                            src: 'company-match.svg',
                            mobileSrc: 'company-match-mobile.svg'
                        }
                    }]
                },
                quote: {
                    text: 'Gokind increases companies employer branding and customer retention by simply transforming what’s already there.',
                    person: {
                        name: 'Sarah Bergström',
                        title: 'Head of Human Resources',
                        img: 'sarah.webp'
                    }
                },
                steps: [{
                        title: 'Recieve your Impact Hub',
                        desc: 'Let our AI create one for free or get guided by us.',
                        img: 'step-1.svg'
                    },
                    {
                        title: 'Show your work',
                        desc: 'Let the world see your goals and achievements.',
                        img: 'step-2.svg'
                    }, {
                        title: 'Collect the benefits',
                        desc: 'Connect with talents, customers, investors.',
                        img: 'step-4.svg'
                    }
                ]
            },
        }
    },
    watch: {
        '$route.query.t': function (tab) {
            this.d = (tab) ? this.company : this.person
        }
    },
    created: function () {
        if (this.$route.query.t) this.d = this.company
        else this.d = this.person
        window.scrollTo(0, 0)
        if (window.innerWidth < 600) this.factsAni()

    }
}
export default {
    name: 'Stepper',
    data: function () {
        return {
            activeStep: 0,
            stepInterval: null,
        }
    },
    props: {
        steps: Array,
        header: {
            default: 'Easy as one, two, three.',
            type: String
        },
        desc: String,
        type: {
            default: 'progress',
            type: String
        },
        hideNumbers: Boolean,
        hideHeader: Boolean
    },
    methods: {
        focusStepInterval: function () {
            this.stepInterval = setInterval(() => {
                this.activeStep++
                if (this.activeStep >= this.steps.length) this.activeStep = 0
            }, 5000)
        },
        changeSubFeatureManually: function (index) {
            this.activeStep = index
            if (this.type === 'progress') {
                clearInterval(this.stepInterval)
                this.focusStepInterval()
            }
        },
    },
    created: function () {
        if (this.type === 'progress') this.focusStepInterval()
    }
}